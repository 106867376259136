var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Nation Administrative Structures")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("Manage nation's administrative structures")) + " ")])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_vm.items.length ? _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "w-50"
  }, [_c('h5', {
    staticClass: "font-weight-bolder mb-5 border-bottom pb-5"
  }, [_vm._v(_vm._s(_vm.$t("Structures list")))]), _vm.isLoadingAdministrative ? _c('div', {
    staticClass: "spinner"
  }) : _vm._e(), _vm.items.length ? _c('div', [_c('v-jstree', {
    attrs: {
      "data": _vm.items,
      "size": "large",
      "whole-row": ""
    },
    on: {
      "item-click": _vm.itemClick
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(item) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }]
        }, [_c('i', {
          staticClass: "mdi mdi-domain text-primary",
          attrs: {
            "role": "presentation"
          }
        }), _vm._v(" " + _vm._s(item.model.text) + " ")])];
      }
    }], null, false, 1457595231)
  })], 1) : _vm._e()]), _vm.detailShow ? _c('div', {
    staticClass: "w-50"
  }, [_c('h5', {
    staticClass: "font-weight-bolder mb-5"
  }, [_vm._v(_vm._s(_vm.$t("Details")))]), _c('div', {
    staticClass: "border-bottom border-top mb-2 pb-2 pt-3"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Name")) + ": ")]), _c('span', {
    staticClass: "font-size-md mt-1"
  }, [_vm._v(" " + _vm._s(_vm.selectedElement.name) + " ")])]), _c('div', {
    staticClass: "border-bottom mb-2 pb-3 pt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Description")) + ": ")]), _c('span', {
    staticClass: "font-size-md mt-1"
  }, [_vm._v(" " + _vm._s(_vm.selectedElement.description) + " ")])]), _c('div', {
    staticClass: "border-bottom mb-2 pb-3 pt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Territorial jurisdiction")) + ": ")]), _c('span', {
    staticClass: "font-size-md mt-1"
  }, [_vm._v(" " + _vm._s(_vm.selectedElement.territorial_jurisdiction) + " ")])]), _c('div', {
    staticClass: "border-bottom mb-2 pb-3 pt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Jurisdictional categories")) + ": ")]), _vm._l(_vm.selectedElement.categories, function (categories) {
    return _c('span', {
      key: categories.id,
      staticClass: "font-size-md"
    }, [_c('p', {
      staticClass: "label label-lg label-light-info font-weight-bolder label-inline text-capitalize mr-2 mt-2"
    }, [_vm._v(" " + _vm._s(categories.name) + " ")])]);
  })], 2), _c('div', {
    staticClass: "border-bottom mb-2 pb-3 pt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Legal type")) + ": ")]), _c('span', {
    staticClass: "font-size-md mt-1"
  }, _vm._l(_vm.selectedElement.legal_type, function (legal) {
    return _c('span', {
      key: legal.id,
      staticClass: "font-size-md"
    }, [_c('p', {
      staticClass: "label label-lg label-light-success font-weight-bolder label-inline text-capitalize mt-1 mr-2"
    }, [_vm._v(" " + _vm._s(legal.country) + " - " + _vm._s(legal.name) + " ")])]);
  }), 0)]), _c('div', {
    staticClass: "border-bottom mb-2 pb-3 pt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("VAT")) + ": ")]), _vm._l(_vm.selectedElement.legal_entity.extracompanyvat_set, function (v) {
    return _c('span', {
      key: v.id,
      staticClass: "font-size-md mt-1"
    }, [_vm._v(" " + _vm._s(v.vat) + " ")]);
  })], 2), _c('div', {
    staticClass: "border-bottom mb-2 pb-3 pt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Websites")) + ": ")]), _vm._l(_vm.selectedElement.website, function (web) {
    return _c('span', {
      key: web.id
    }, [_c('a', {
      staticClass: "label-break label label-md label-light-primary font-weight-bolder label-inline mr-2 mt-1",
      attrs: {
        "href": web.url,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(web.url) + " ")])]);
  })], 2), _c('div', {
    staticClass: "border-bottom mb-2 pb-3 pt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Addresses")) + ": ")]), _vm.selectedElement.legal_entity.companyaddress_set.length != 0 ? _c('span', {
    staticClass: "font-size-md mt-1"
  }, _vm._l(_vm.selectedElement.legal_entity.companyaddress_set, function (addr) {
    return _c('span', {
      key: addr.id,
      staticClass: "font-size-md mt-1"
    }, [_vm._v(" " + _vm._s(addr.address.formatted) + " ")]);
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "border-bottom mb-2 pb-3 pt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Telephone numbers")) + ": ")]), _c('span', {
    staticClass: "font-size-md mt-1"
  }, _vm._l(_vm.selectedElement.legal_entity.telcontactscompany_set.length != 0, function (phone) {
    return _c('span', {
      key: phone.id,
      staticClass: "font-size-md mt-1"
    }, [_vm._v(" " + _vm._s(phone) + " ")]);
  }), 0)])]) : _vm._e()]) : _c('p', [_vm._v(_vm._s(this.$t("The selected country does not have administrative structures")) + ".")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }