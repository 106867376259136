<template>
  <div>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">
            {{ $t("Nation Administrative Structures") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">
            {{ $t("Manage nation's administrative structures") }}
          </span>
        </div>
      </div>
      <div class="card-body detail">
        <div v-if="items.length" class="d-flex">
          <div class="w-50">
            <h5 class="font-weight-bolder mb-5 border-bottom pb-5">{{ $t("Structures list") }}</h5>
            <div v-if="isLoadingAdministrative" class="spinner"></div>
            <div v-if="items.length">
              <v-jstree :data="items" size="large" whole-row @item-click="itemClick">
                <template slot-scope="item">
                  <div v-b-tooltip.hover>
                    <i class="mdi mdi-domain text-primary" role="presentation"></i>
                    {{ item.model.text }}
                  </div>
                </template>
              </v-jstree>
            </div>
          </div>
          <div v-if="detailShow" class="w-50">
            <h5 class="font-weight-bolder mb-5">{{ $t("Details") }}</h5>
            <div class="border-bottom border-top mb-2 pb-2 pt-3">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("Name") }}:
              </span>
              <span class=" font-size-md mt-1">
                {{ selectedElement.name }}
              </span>
            </div>
            <div class="border-bottom mb-2 pb-3 pt-2">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("Description") }}:
              </span>
              <span class=" font-size-md mt-1">
                {{ selectedElement.description }}
              </span>
            </div>
            <div class="border-bottom mb-2 pb-3 pt-2">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("Territorial jurisdiction") }}:
              </span>
              <span class=" font-size-md mt-1">
                {{ selectedElement.territorial_jurisdiction }}
              </span>
            </div>
            <div class="border-bottom mb-2 pb-3 pt-2">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("Jurisdictional categories") }}:
              </span>
              <span v-for="categories in selectedElement.categories" :key="categories.id" class="font-size-md">
                <p class="label label-lg label-light-info font-weight-bolder label-inline text-capitalize mr-2 mt-2">
                  {{ categories.name }}
                </p>
              </span>
            </div>
            <div class="border-bottom mb-2 pb-3 pt-2">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("Legal type") }}:
              </span>
              <span class=" font-size-md mt-1">
                <span v-for="legal in selectedElement.legal_type" :key="legal.id" class=" font-size-md">
                  <p class="label label-lg label-light-success font-weight-bolder label-inline text-capitalize mt-1 mr-2">
                    {{ legal.country }} - {{ legal.name }}
                  </p>
                </span>
              </span>
            </div>
            <div class="border-bottom mb-2 pb-3 pt-2">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("VAT") }}:
              </span>
              <span v-for="v in selectedElement.legal_entity.extracompanyvat_set" :key="v.id" class=" font-size-md mt-1">
                {{ v.vat }}
              </span>
            </div>
            <div class="border-bottom mb-2 pb-3 pt-2">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("Websites") }}:
              </span>
              <span v-for="web in selectedElement.website" :key="web.id">
                <a :href="web.url" target="_blank"
                  class="label-break label label-md label-light-primary font-weight-bolder label-inline mr-2 mt-1">
                  {{ web.url }}
                </a>
              </span>
            </div>
            <div class="border-bottom mb-2 pb-3 pt-2">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("Addresses") }}:
              </span>
              <span v-if="selectedElement.legal_entity.companyaddress_set.length != 0" class="font-size-md mt-1">
                <span v-for="addr in selectedElement.legal_entity.companyaddress_set" :key="addr.id"
                  class=" font-size-md mt-1">
                  {{ addr.address.formatted }}
                </span>
              </span>
            </div>
            <div class="border-bottom mb-2 pb-3 pt-2">
              <span class="font-weight-bold font-size-md mt-1 mr-4">
                {{ $t("Telephone numbers") }}:
              </span>
              <span class="font-size-md mt-1">
                <span v-for="phone in selectedElement.legal_entity.telcontactscompany_set.length != 0" :key="phone.id"
                  class=" font-size-md mt-1">
                  {{ phone }}
                </span>
              </span>
            </div>
          </div>
        </div>
        <p v-else>{{ this.$t("The selected country does not have administrative structures") }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { backendErrorSwal } from "@/core/helpers/swal";
import NationsAdministrativeStructureService from "@/core/services/nation/nations-administrativestructures.service";
import VJstree from 'vue-jstree'
export default ({
  components: {
    VJstree,
  },
  props: {
    nation: {
      type: Object,
      required: true,
    },
    administrativeStructures: {
      type: Array,
      required: true,
    },
    isLoadingAdministrative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [],
      tree: [],
      detailShow: false,
      selectedElement: {},
    }
  },

  created() {
    this.orderItems();
  },
  methods: {
    orderItems() {
      let itemsTree = [];
      this.items = itemsTree.concat(getItemsWithChildren(this.administrativeStructures));
    },
    async itemClick(node) {
      await NationsAdministrativeStructureService.getOne({ id: node.model.id, expand: 'legal_entity' }).then((results) => {
        this.selectedElement = results;
        this.detailShow = true;
        document.body.scrollIntoView({ behavior: "smooth" });
      }).catch(err => {
        console.log(err);
        backendErrorSwal(err, err?.response?.data?.detail);
      });
      this.nodeId = node.model.id;
    },
  }
})

function getItemsWithChildren(arr) {
  const items = [];

  function addChildren(item, children) {
    children.forEach(child => {
      item.children.push({
        text: child.name,
        id: child.id,
        parent: child.parent ?? null,
        children: [],
        opened: true,
      })
    })
    children.forEach((child) => {
      const nestedChildren = arr.filter((nestedChild) => nestedChild.parent == child.id);
      if (nestedChildren.length > 0) {
        addChildren(item.children.filter(el => el.id == child.id)[0], nestedChildren);
      }
    });
  }

  arr.forEach((item) => {
    if (!item.parent) {
      const children = arr.filter((child) => child.parent == item.id);
      items.push({
        text: item.name,
        id: item.id,
        children: [],
        parent: null,
        opened: true,
      });
      if (children.length > 0) {
        addChildren(items[items.length - 1], children);
      }
    }
  });

  return items;
}
</script>

<style scoped>
.label-break {
  height: auto;
  white-space: break-spaces;
  line-break: anywhere;
}
</style>

<style lang="scss">
.tree-anchor {
  &.tree-selected {
    font-weight: bolder;
  }

  &>div {
    text-overflow: ellipsis;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;

    @media (min-width: 1025px) {
      max-width: 250px;
    }

    @media (min-width: 1441px) {
      max-width: 480px;
    }
  }
}
</style>
